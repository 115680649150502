import { createContext, useContext, useEffect, useReducer } from 'react';

import { useAuthStateContext } from '@/modules/auth/providers';
import webApi from '@/modules/api/web-api';
import * as WebApiTypes from '@/modules/api/@types';
import { CONTENT } from '@/modules/constants/pacificleague';
import { getDeviceId } from '@/utils/device';

const { NEXT_PUBLIC_APP_ENV } = process.env;

const prefix = 'pacificleagueStore/';
export const PlStoreActionTypes = {
  FETCH_INITIALIZE: `${prefix}FETCH_INITIALIZE`,
  CHANE_DISP_SCORE: `${prefix}CHANE_DISP_SCORE`,
} as const;
export type PlStoreActionTypes = (typeof PlStoreActionTypes)[keyof typeof PlStoreActionTypes];

type State = {
  purchaseInfo: WebApiTypes.PurchaseConfirm;
  isDispScore: boolean;
  isFetched: boolean;
  debugTime: string;
};

const initialState: State = {
  purchaseInfo: null,
  isDispScore: true,
  isFetched: false,
  debugTime: '',
};

type Action =
  | {
      type: typeof PlStoreActionTypes.FETCH_INITIALIZE;
      payload: {
        purchaseInfo?: WebApiTypes.PurchaseConfirm;
        isBookmarked?: boolean;
        isDispScore: boolean;
        debugTime?: string;
      };
    }
  | {
      type: typeof PlStoreActionTypes.CHANE_DISP_SCORE;
      payload: {
        isDispScore: boolean;
      };
    };

const PlStoreStateContext = createContext<State>(initialState);
const PlStoreDispatchContext = createContext<React.Dispatch<Action>>(() => null);
export const usePlStoreStateContext = (): State => useContext<State>(PlStoreStateContext);
export const usePlStoreDispatchContext = (): React.Dispatch<Action> =>
  useContext<React.Dispatch<Action>>(PlStoreDispatchContext);

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case PlStoreActionTypes.FETCH_INITIALIZE: {
      const { purchaseInfo, isDispScore, debugTime } = action.payload;

      return {
        ...state,
        purchaseInfo,
        isDispScore,
        isFetched: true,
        debugTime,
      };
    }
    case PlStoreActionTypes.CHANE_DISP_SCORE: {
      const { isDispScore } = action.payload;
      localStorage.setItem('pl_schedule_disp_score', isDispScore.toString());

      return {
        ...state,
        isDispScore,
      };
    }
    default: {
      throw new Error('no such action type');
    }
  }
};

const fetchPurchaseConfirm = async (): Promise<WebApiTypes.PurchaseConfirm> => {
  const { body } = await webApi()
    .purchase.confirm.get({
      query: {
        content_id: CONTENT.ID,
      },
      config: {
        withCredentials: true,
      },
    })
    .catch(async () => ({
      body: {
        result: null as WebApiTypes.PurchaseConfirm,
        status: 'fail',
      },
    }));

  return body?.result;
};

const fetchDebugInfo = async (): Promise<WebApiTypes.MemberDebugInfo> => {
  const { body } = await webApi()
    .member.debug_info.get({
      query: {
        device_id: getDeviceId(),
      },
      config: {
        withCredentials: true,
      },
    })
    .catch(async () => ({
      body: {
        result: null as WebApiTypes.MemberDebugInfo,
        status: 'fail',
      },
    }));

  return body?.result;
};

type Props = {
  children?: React.ReactNode;
};
const PlStoreProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAuthenticated, isFetched } = useAuthStateContext();

  useEffect(() => {
    const fetchData = async () => {
      const storageIsDispScore: string | boolean = localStorage.getItem('pl_schedule_disp_score');
      const isDispScore = storageIsDispScore === null ? true : storageIsDispScore === 'true';

      let debugTime = '';
      if (NEXT_PUBLIC_APP_ENV !== 'production') {
        const debugInfo = await fetchDebugInfo();
        debugTime = debugInfo?.debug_time ?? '';
      }

      if (!isAuthenticated) {
        dispatch({
          type: PlStoreActionTypes.FETCH_INITIALIZE,
          payload: {
            isDispScore,
            debugTime,
          },
        });

        return;
      }

      const purchaseInfo = await fetchPurchaseConfirm();

      dispatch({
        type: PlStoreActionTypes.FETCH_INITIALIZE,
        payload: {
          purchaseInfo,
          isDispScore,
          debugTime,
        },
      });
    };

    isFetched && fetchData().finally(() => null);
  }, [isAuthenticated, isFetched]);

  return (
    <PlStoreStateContext.Provider value={state}>
      <PlStoreDispatchContext.Provider value={dispatch}>{children}</PlStoreDispatchContext.Provider>
    </PlStoreStateContext.Provider>
  );
};

export default PlStoreProvider;
