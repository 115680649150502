/* eslint-disable import/prefer-default-export */
const { NEXT_PUBLIC_IM_URL } = process.env;

export const CONTENT = {
  ID: 252451,
  TYPE: '5',
  NAME_TITLE: 'Rakuten パ・リーグSpecial',
  PARENT_ID: null,
  RATING_TYPE: '0',
  RESALE_TYPE: 0,
};

export const PAGE_TYPE_TOP = 28;
export const UNIT_ID_VOD = 915;
export const UNIT_TYPE_SEARCH_TAG = 16;
export const MULTI_ID_LIVE = 1651;
export const MULTI_ID_TEAM_RAKUTEN = 1655;
export const MULTI_IDS_TEAM = [
  1659, // 日本ハム
  MULTI_ID_TEAM_RAKUTEN, // 楽天
  1657, // 西武
  1656, // ロッテ
  1660, // オリックス
  1658, // ソフトバンク
];

export const TEAM_NAME = ['fighters', 'eagles', 'lions', 'marines', 'buffaloes', 'hawks'] as const;
export type TEAM_KEY_TYPE = typeof TEAM_NAME[number];

export const TEAM: Record<TEAM_KEY_TYPE, Record<'LABEL' | 'LABEL_SHORT' | 'BANNER', string>> = {
  fighters: {
    LABEL: '北海道日本ハムファイターズ',
    LABEL_SHORT: '日本ハム',
    BANNER: '2004001.jpg',
  },
  eagles: {
    LABEL: '東北楽天ゴールデンイーグルス',
    LABEL_SHORT: '楽天',
    BANNER: '2005003.jpg',
  },
  lions: {
    LABEL: '埼玉西武ライオンズ',
    LABEL_SHORT: '西武',
    BANNER: '2008001.jpg',
  },
  marines: {
    LABEL: '千葉ロッテマリーンズ',
    LABEL_SHORT: 'ロッテ',
    BANNER: '1992001.jpg',
  },
  buffaloes: {
    LABEL: 'オリックス・バファローズ',
    LABEL_SHORT: 'オリックス',
    BANNER: '2005002.jpg',
  },
  hawks: {
    LABEL: '福岡ソフトバンクホークス',
    LABEL_SHORT: 'ソフトバンク',
    BANNER: '2005001.jpg',
  },
};

type TeamsType = {
  [key: number]: {
    id: number;
    name: string;
    logoUrl: string;
    logoLargeUrl: string;
    stadiumImgUrl: string;
    pageType: number;
    pageUrlParam: string;
    scheduleSelectValue: string;
    scheduleSelectValueSp: string;
    rankingSelectValueSp: string;
  };
};
export const TEAMS: TeamsType = {
  1659: {
    id: 2004001,
    name: '北海道日本ハムファイターズ',
    logoUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2004001_initial.svg`,
    logoLargeUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2004001_team.svg`,
    stadiumImgUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/stadium/2004001.jpg`,
    pageType: 34,
    pageUrlParam: 'fighters',
    scheduleSelectValue: '日本ハム',
    scheduleSelectValueSp: '日本ハム',
    rankingSelectValueSp: '日',
  },
  1655: {
    id: 2005003,
    name: '東北楽天ゴールデンイーグルス',
    logoUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2005003_initial.svg`,
    logoLargeUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2005003_team.svg`,
    stadiumImgUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/stadium/2005003.jpg`,
    pageType: 33,
    pageUrlParam: 'eagles',
    scheduleSelectValue: '楽天',
    scheduleSelectValueSp: '楽天',
    rankingSelectValueSp: '楽',
  },
  1657: {
    id: 2008001,
    name: '埼玉西武ライオンズ',
    logoUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2008001_initial.svg`,
    logoLargeUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2008001_team.svg`,
    stadiumImgUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/stadium/2008001.jpg`,
    pageType: 36,
    pageUrlParam: 'lions',
    scheduleSelectValue: '西武',
    scheduleSelectValueSp: '西武',
    rankingSelectValueSp: '西',
  },
  1656: {
    id: 1992001,
    name: '千葉ロッテマリーンズ',
    logoUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/1992001_initial.svg`,
    logoLargeUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/1992001_team.svg`,
    stadiumImgUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/stadium/1992001.jpg`,
    pageType: 37,
    pageUrlParam: 'marines',
    scheduleSelectValue: 'ロッテ',
    scheduleSelectValueSp: 'ロッテ',
    rankingSelectValueSp: 'ロ',
  },
  1660: {
    id: 2005002,
    name: 'オリックス・バファローズ',
    logoUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2005002_initial.svg`,
    logoLargeUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2005002_team.svg`,
    stadiumImgUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/stadium/2005002.jpg`,
    pageType: 32,
    pageUrlParam: 'buffaloes',
    scheduleSelectValue: 'オリックス',
    scheduleSelectValueSp: 'ｵﾘｯｸｽ',
    rankingSelectValueSp: 'オ',
  },
  1658: {
    id: 2005001,
    name: '福岡ソフトバンクホークス',
    logoUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2005001_initial.svg`,
    logoLargeUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2005001_team.svg`,
    stadiumImgUrl: `${NEXT_PUBLIC_IM_URL}/pacificleague/stadium/2005001.jpg`,
    pageType: 35,
    pageUrlParam: 'hawks',
    scheduleSelectValue: 'ソフトバンク',
    scheduleSelectValueSp: 'ｿﾌﾄﾊﾞﾝｸ',
    rankingSelectValueSp: 'ソ',
  },
};

export type TopBannerType = {
  start: string;
  end: string;
  link: string;
  img: string;
  alt: string;
};

export const ISSUE_UNIT_TITLE = '特集記事';

export const ISSUE_MAX_CNT = 16;

export type IssueType = {
  date: string;
  id: string;
  serial: number;
  team: string;
  time: string;
  title: string;
};

export const SCHEDULE_YEAR_MIN = 2018;

type SortType = {
  value: number;
  title: string;
};
export const SORT_TYPE_LIST: SortType[] = [
  { value: 0, title: 'マッチング' },
  { value: 1, title: '価格順' },
  { value: 2, title: '新着順' },
  { value: 3, title: '更新順' },
  { value: 4, title: '人気順' },
  { value: 5, title: '評価順' },
  { value: 6, title: '50音順' },
];
export const SORT_TYPE_DEFAULT = 2;

export const SORT_ORDER = {
  ASC: 0,
  DESC: 1,
} as const;

export const FORMAT_TYPE = {
  IMAGE: 1,
  DETAIL: 2,
} as const;

export const COUNT_DEFAULT = 90;
export const COUNT_LIST = [10, 30, 90] as const;

export const RATING_TYPE = {
  '0': 'G',
  '12': 'PG12',
  '15': 'R15+',
  '18': 'R18+',
  '99': 'アダルト',
};

export const GAME_INFO_POLLING_INTERVAL = 3 * 60 * 1000;

export const GAME_STATE_TYPE = {
  END: '000', // 試合終了
  BEFORE_GAME: '500', // 試合開始前
  PLAY: '600', // 試合中
  SUSPEND: '699', // 中断中
  CHECK: '700', // チェック中
  GAME_SET: '800', // ゲームセット
  STOP: '900', // 試合中止
  NO_GAME: '901', // ノーゲーム
};
