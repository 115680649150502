/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import Head from 'next/head';
import NewRelic from 'new-relic-agent-react';
import { makeStyles, createStyles } from '@mui/styles';

import Header from '@/modules/layouts/shared/header';
import Footer from '@/modules/layouts/shared/footer';
import PlFooter from '@/modules/layouts/pacificleague/footer';
import LeftNavi from '@/modules/layouts/pacificleague/left-navi';
import PlStoreProvider from '@/modules/domains/pacificleague/providers';
import PackageJson from '@/../package.json';

const useStyles = makeStyles(() =>
  createStyles({
    mainlayout: {
      'html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,select,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video':
        {
          margin: 0,
          padding: 0,
          border: 0,
          verticalAlign: 'baseline',
          font: 'inherit',
          fontSize: '100%',
        },
      'a,a:hover,a:visited,a:link': {
        color: '#fff',
        '&.pl-link-emphasis': {
          textDecoration: 'underline',
          color: '#fbc72a',
        },
      },
      'a:hover': {
        textDecoration: 'none',
      },
      'ol,ul': {
        listStyle: 'none',
      },
      table: {
        borderCollapse: 'collapse',
        borderSpacing: 0,
      },
      '&+footer,.lW-clm-C + footer': {
        borderTop: 'none',
        overflow: 'visible',
      },
      // Above here is reset style.
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      boxSizing: 'border-box',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      zIndex: 10,
      background: '#000',
      color: '#fff',
      fontSize: '14px',
      fontFamily:
        'ヒラギノ角ゴシック, "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ W3", メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", -apple-system, BlinkMacSystemFont, sans-serif',
      fontWeight: 400,
      wordWrap: 'break-word',
      lineHeight: 1,
      '@media(max-width: 969px)': {
        display: 'block',
      },
    },
    mainSection: {
      width: 0,
      flexGrow: 1,
      '@media (min-width: 970px)': {
        minHeight: 'calc(100vh - 80px - (5 / 144 * 100vw))',
      },
      '@media (max-width: 969px)': {
        width: 'auto',
      },
      '& .lSec': {
        '&:first-child,.spPrArea+&': {
          paddingTop: '24px',
          '@media (max-width: 969px)': {
            paddingTop: '16px',
          },
        },
        paddingLeft: '24px',
        paddingRight: '24px',
        '@media (max-width: 969px)': {
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
      '& .plPrBox + .lSec': {
        paddingTop: '24px',
      },
    },
  }),
);

const { NEXT_PUBLIC_NEWRELIC_LICENSE_KEY } = process.env;
const { NEXT_PUBLIC_NEWRELIC_APPLICATION_ID } = process.env;
const { NEXT_PUBLIC_IM_URL } = process.env;

type Props = {
  children?: React.ReactNode;
  title?: string;
  snsTitle?: string;
  description?: string;
  snsDescription?: string;
  url?: string;
  imageUrl?: string;
  ogImageUrl?: string;
};
const Layout: React.FC<Props> = ({
  children,
  title,
  snsTitle,
  description,
  snsDescription,
  url,
  imageUrl,
  ogImageUrl,
}) => {
  const classes = useStyles();

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width, user-scalable=no" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="rtv:live:v" content={PackageJson.version} />
        <meta name="google-site-verification" content="YPR-h5vt1HDjxbK_BFKP6vvXiDqw6BrX79a0KuALHsM" />
        {title && <title>{title} | 楽天TV</title>}
        {description && <meta name="description" content={description} />}
        <meta name="robots" content="noydir" />
        <meta name="robots" content="noodp" />
        <meta name="format-detection" content="telephone=no" />
        <meta property="og:type" content="website" />
        {snsTitle && <meta property="og:title" content={`${snsTitle}`} />}
        <meta
          property="og:url"
          content={url || new URL(window?.location?.href ?? '').toString().replace(/\?.*$/, '')}
        />
        <meta property="og:site_name" content="楽天TV" />
        {(snsDescription || description) && <meta property="og:description" content={snsDescription || description} />}
        <meta property="fb:app_id" content="1398503433742723" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@rakutentv_japan" />
        {snsTitle && <meta name="twitter:title" content={`${snsTitle}`} />}
        {(snsDescription || description) && <meta name="twitter:description" content={snsDescription || description} />}
        {url && <meta name="twitter:url" content={url} />}
        <meta
          property="og:image"
          content={ogImageUrl || imageUrl || `${NEXT_PUBLIC_IM_URL}/content/42/15/252451/main.jpg`}
        />
        <meta name="twitter:image:src" content={imageUrl || `${NEXT_PUBLIC_IM_URL}/content/42/15/252451/main.jpg`} />
        <meta name="application-name" content="楽天TV" />
        <meta name="msapplication-TileColor" content="#BF0000" />
        <meta
          name="msapplication-square70x70logo"
          content="https://qa-im.akimg.tv.rakuten.co.jp/img/msapplication/tiny.png"
        />
        <meta
          name="msapplication-square150x150logo"
          content="https://qa-im.akimg.tv.rakuten.co.jp/img/msapplication/square.png"
        />
        <meta
          name="msapplication-wide310x150logo"
          content="https://qa-im.akimg.tv.rakuten.co.jp/img/msapplication/wide.png"
        />
        <meta
          name="msapplication-square310x310logo"
          content="https://qa-im.akimg.tv.rakuten.co.jp/img/msapplication/large.png"
        />
        {NEXT_PUBLIC_NEWRELIC_LICENSE_KEY && (
          <NewRelic licenseKey={NEXT_PUBLIC_NEWRELIC_LICENSE_KEY} applicationID={NEXT_PUBLIC_NEWRELIC_APPLICATION_ID} />
        )}
      </Head>
      <Header />
      <main className={classes.mainlayout}>
        <PlStoreProvider>
          <LeftNavi />
          <section className={classes.mainSection}>{children}</section>
        </PlStoreProvider>
      </main>
      <PlFooter />
      <Footer />
    </>
  );
};
export default Layout;
