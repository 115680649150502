import { makeStyles, createStyles } from '@mui/styles';
import Link from 'next/link';
import Image from '@/modules/components/image';

const useStyles = makeStyles(() =>
  createStyles({
    footer: {
      fontFamily:
        "'ヒラギノ角ゴシック',Hiragino Sans,'ヒラギノ角ゴ ProN W3',Hiragino Kaku Gothic ProN,'ヒラギノ角ゴ W3','メイリオ',Meiryo,'ＭＳ Ｐゴシック',MS PGothic,-apple-system,BlinkMacSystemFont,sans-serif",
      '& .plFoot': {
        position: 'relative',
        backgroundColor: '#292929',
        textAlign: 'center',
        '&_team': {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          '&::after': {
            content: "''",
            marginTop: 'calc(1.5vw + 20px)',
            display: 'block',
            width: '100%',
            height: '200px',
            borderTop: '1px solid #4d4d4d',
            borderTopRightRadius: '2000px 300px',
            borderTopLeftRadius: '2000px 300px',
          },
          '&List': {
            display: 'flex',
            justifyContent: 'space-around',
            textAlign: 'center',
          },
          '&Item': {
            padding: 'calc(2vw + 20px) 1.5vw 0',
            outline: 0,
            '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
            '& a': {
              display: 'block',
              '& img': {
                verticalAlign: 'bottom',
                height: '5.5vw',
                maxHeight: '84px',
                minHeight: '60px',
              },
            },
          },
        },
        '&_team + .plFoot_navi': {
          marginTop: '-160px',
          '@media (max-width: 969px)': {
            marginTop: '-190px',
          },
        },
        '&_navi': {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          textAlign: 'left',
          '@media (max-width: 969px)': {
            textAlign: 'center',
          },
          '&_cell': {
            margin: '0 3vw',
            boxSizing: 'border-box',
            width: '18%',
            '@media (max-width: 969px)': {
              width: '100%',
              margin: '0 16px',
            },
          },
          '&,& a': {
            color: '#828282 !important',
          },
          '&_ttl': {
            fontSize: '14px',
            fontWeight: 700,
          },
          '&_cell>.plFoot_navi_ttl': {
            marginTop: '20px',
          },
          '&_list': {
            padding: '12px 0',
            borderBottom: '1px solid #404040',
          },
          '&_item': {
            padding: '6px 0',
            fontSize: '12px',
            lineHeight: 1.4,
            fontFeatureSettings: "'palt'",
            '& a': {
              textDecoration: 'underline',
            },
            '@media (max-width: 969px)': {
              display: 'inline-block',
              '&+.plFoot_navi_item': {
                marginLeft: '1em',
              },
            },
          },
        },
        '&_logo': {
          marginTop: 'calc(2.5vw + 20px)',
          '& img': {
            height: 'calc(24px + 2.3vw) !important',
            maxHeight: '70px !important',
          },
        },
        '&_copy': {
          marginTop: '1.5vw',
          display: 'block',
          padding: '0 16px',
          lineHeight: 1.6,
          fontSize: '10px',
          color: '#555',
        },
        '&_figure': {
          display: 'block',
          marginTop: 'calc(-350px + 2vw)',
          boxSizing: 'border-box',
          height: 480,
          background: 'url(/images/pacificleague/foot_figure.svg) no-repeat bottom 40px center',
        },
      },
    },
  }),
);

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;
const { NEXT_PUBLIC_IM_URL } = process.env;

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <footer className={classes.footer}>
        <div className="plFoot">
          <div className="plFoot_team">
            <ul className="plFoot_teamList">
              <li className="plFoot_teamItem">
                <Link href="/pacificleague/team/fighters/" passHref>
                  <Image
                    src={`${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2004001_team.svg`}
                    alt="北海道日本ハムファイターズ"
                    width={98}
                    height={79}
                  />
                </Link>
              </li>
              <li className="plFoot_teamItem">
                <Link href="/pacificleague/team/eagles/" passHref>
                  <Image
                    src={`${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2005003_team.svg`}
                    alt="東北楽天ゴールデンイーグルス"
                    width={98}
                    height={79}
                  />
                </Link>
              </li>
              <li className="plFoot_teamItem">
                <Link href="/pacificleague/team/lions/" passHref>
                  <Image
                    src={`${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2008001_team.svg`}
                    alt="埼玉西武ライオンズ"
                    width={98}
                    height={79}
                  />
                </Link>
              </li>
            </ul>
            <ul className="plFoot_teamList">
              <li className="plFoot_teamItem">
                <Link href="/pacificleague/team/marines/" passHref>
                  <Image
                    src={`${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/1992001_team.svg`}
                    alt="千葉ロッテマリーンズ"
                    width={98}
                    height={79}
                  />
                </Link>
              </li>
              <li className="plFoot_teamItem">
                <Link href="/pacificleague/team/buffaloes/" passHref>
                  <Image
                    src={`${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2005002_team.svg`}
                    alt="オリックス・バファローズ"
                    width={98}
                    height={79}
                  />
                </Link>
              </li>
              <li className="plFoot_teamItem">
                <Link href="/pacificleague/team/hawks/" passHref>
                  <Image
                    src={`${NEXT_PUBLIC_IM_URL}/pacificleague/teamlogo/2005001_team.svg`}
                    alt="福岡ソフトバンクホークス"
                    width={98}
                    height={79}
                  />
                </Link>
              </li>
            </ul>
          </div>

          <nav className="plFoot_navi">
            <section className="plFoot_navi_cell">
              <h2 className="plFoot_navi_ttl">配信日程・スコア</h2>
              <ul className="plFoot_navi_list">
                <li className="plFoot_navi_item">
                  <Link href="/pacificleague/schedule/fighters/">北海道日本ハムファイターズの日程</Link>
                </li>
                <li className="plFoot_navi_item">
                  <Link href="/pacificleague/schedule/eagles/">東北楽天ゴールデンイーグルスの日程</Link>
                </li>
                <li className="plFoot_navi_item">
                  <Link href="/pacificleague/schedule/lions/">埼玉西武ライオンズの日程</Link>
                </li>
                <li className="plFoot_navi_item">
                  <Link href="/pacificleague/schedule/marines/">千葉ロッテマリーンズの日程</Link>
                </li>
                <li className="plFoot_navi_item">
                  <Link href="/pacificleague/schedule/buffaloes/">オリックス・バファローズの日程</Link>
                </li>
                <li className="plFoot_navi_item">
                  <Link href="/pacificleague/schedule/hawks/">福岡ソフトバンクホークスの日程</Link>
                </li>
              </ul>
              <h2 className="plFoot_navi_ttl">2023年の動画</h2>
              <ul className="plFoot_navi_list">
                <li className="plFoot_navi_item">
                  <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/search/tag/2412/?sort_type=2`}>2023年 見逃し配信</a>
                </li>
                <li className="plFoot_navi_item">
                  <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/search/tag/2413/?sort_type=2`}>2023年 ダイジェスト</a>
                </li>
                <li className="plFoot_navi_item">
                  <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/search/tag/2414/?sort_type=2`}>2023年 ヒーローインタビュー</a>
                </li>
              </ul>
            </section>
            <section className="plFoot_navi_cell">
              <h2 className="plFoot_navi_ttl">2022年の動画</h2>
              <ul className="plFoot_navi_list">
                <li className="plFoot_navi_item">
                  <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/search/tag/2365/?sort_type=2`}>2022年 見逃し配信</a>
                </li>
                <li className="plFoot_navi_item">
                  <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/search/tag/2366/?sort_type=2`}>2022年 ダイジェスト</a>
                </li>
                <li className="plFoot_navi_item">
                  <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/search/tag/2367/?sort_type=2`}>2022年 ヒーローインタビュー</a>
                </li>
              </ul>
              <h2 className="plFoot_navi_ttl">2021年の動画</h2>
              <ul className="plFoot_navi_list">
                <li className="plFoot_navi_item">
                  <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/search/tag/2209/?sort_type=2`}>2021年 見逃し配信</a>
                </li>
                <li className="plFoot_navi_item">
                  <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/search/tag/2210/?sort_type=2`}>2021年 ダイジェスト</a>
                </li>
                <li className="plFoot_navi_item">
                  <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/search/tag/2211/?sort_type=2`}>2021年 ヒーローインタビュー</a>
                </li>
              </ul>
            </section>
            <section className="plFoot_navi_cell">
              <ul className="plFoot_navi_list">
                <li className="plFoot_navi_item">
                  <h2 className="plFoot_navi_ttl">
                    <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/pacificleague/lp/general/#sec-about`}>
                      パ・リーグ Specialの特徴
                    </a>
                  </h2>
                </li>
              </ul>
              <ul className="plFoot_navi_list">
                <li className="plFoot_navi_item">
                  <h2 className="plFoot_navi_ttl">
                    <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/pacificleague/lp/general/#sec-price`}>料金プラン</a>
                  </h2>
                </li>
              </ul>
              <ul className="plFoot_navi_list">
                <li className="plFoot_navi_item">
                  <h2 className="plFoot_navi_ttl">
                    <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/pacificleague/lp/general/#pl-qa`}>FAQ</a>
                  </h2>
                </li>
              </ul>
              <h2 className="plFoot_navi_ttl">視聴方法</h2>
              <ul className="plFoot_navi_list">
                <li className="plFoot_navi_item">
                  <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/pacificleague/spl000001/`}>テレビ</a>
                </li>
                <li className="plFoot_navi_item">
                  <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/guide/pc/`}>パソコン</a>
                </li>
                <li className="plFoot_navi_item">
                  <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/guide/sp/`}>スマホ＆タブレット</a>
                </li>
                <li className="plFoot_navi_item">
                  <a href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/guide/tv_playstation4/`}>ゲーム機</a>
                </li>
              </ul>
            </section>
          </nav>
          <div className="plFoot_logo">
            <Image
              src={`${NEXT_PUBLIC_IM_URL}/static/pacificleague/_common/img/logo02.png`}
              alt="パ・リーグ Special"
              width={369}
              height={57}
            />
          </div>
          <span className="plFoot_copy">
            ©H.N.F. ©Rakuten Eagles ©SEIBU Lions
            <br />
            ©C.L.M. ©ORIX Buffaloes ©SoftBank HAWKS
            <br />
            ライブ中のデータ提供 ©2023 Japan Baseball Data Inc.
          </span>
          <span className="plFoot_figure" />
        </div>
      </footer>
    </>
  );
};

export default Footer;
