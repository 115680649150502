import { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import { makeStyles, createStyles } from '@mui/styles';
import { Button, Modal, Backdrop, Fade } from '@mui/material';

import { usePlStoreStateContext } from '@/modules/domains/pacificleague/providers';
import { ResDataType } from '@/pages/api/time';

dayjs.locale(ja);

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      '& ol,& ul': {
        listStyle: 'none',
      },
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0,0,0,.6)',
        backdropFilter: 'saturate(2) blur(8px)',
      },
      '& .modal_base': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '95vh',
        maxWidth: '95vw',
        borderRadius: '24px',
        background: '#242424',
        overflowX: 'hidden',
        overflowY: 'auto',
        boxSizing: 'border-box',
        outline: 'none',
        '@media (max-width: 969px)': {
          width: '100%',
          maxWidth: '100%',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          transition: 'all 0.4s ease',
          borderRadius: '16px',
        },
      },
      '& .modal': {
        '&_closeBtn': {
          position: 'absolute',
          display: 'block',
          width: '70px',
          height: '70px',
          top: 0,
          right: 0,
          opacity: 0.5,
          background:
            'url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19%206.41L17.59%205L12%2010.59L6.41%205L5%206.41L10.59%2012L5%2017.59L6.41%2019L12%2013.41L17.59%2019L19%2017.59L13.41%2012L19%206.41Z%22%20fill%3D%22%23FFFFFF%22%2F%3E%3C%2Fsvg%3E) no-repeat center center / 40%',
          cursor: 'pointer',
          '&:hover': {
            opacity: 1,
          },
          '@media (max-width: 969px)': {
            width: '50px',
            height: '50px',
            backgroundSize: '50%',
          },
        },
        '&_in': {
          boxSizing: 'border-box',
          padding: '40px 40px 0',
          maxHeight: 'calc(100vh - 80px)',
        },
        '&_head': {
          fontFeatureSettings: "'palt'",
          textAlign: 'center',
          color: '#fff',
        },
        '&_ttl': {
          fontWeight: 700,
          fontSize: '34px',
          lineHeight: 1,
        },
        '&_ttl + .modal_copy': {
          marginTop: '14px',
        },
        '&_copy': {
          fontSize: '20px',
          lineHeight: 1.6,
        },
        '&_body': {
          marginTop: '38px',
        },
        '&_tbl': {
          display: 'flex',
          alignItems: 'flex-start',
          width: '100%',
          color: '#fff',
          borderRight: '1px solid #777',
          '@supports (margin-bottom: constant(safe-area-inset-bottom))': {
            marginBottom: 'constant(safe-area-inset-bottom) !important',
          },
          '@supports (margin-bottom: env(safe-area-inset-bottom))': {
            marginBottom: 'env(safe-area-inset-bottom) !important',
          },
        },
        '&_clm': {
          boxSizing: 'border-box',
          '&-h': {
            flexGrow: 1,
            width: '166px',
            '& .modal_cell': {
              background: 'rgba(0,0,0, .3)',
              justifyContent: 'start',
              borderLeft: 'none',
              letterSpacing: 0,
            },
          },
          '&-free': {
            width: '105px',
            textAlign: 'center',
            color: '#fff',
            '& .modal_planName': {
              fontSize: '19px',
              fontWeight: 400,
              whiteSpace: 'nowrap',
            },
            '& .modal_cell': {
              fontFamily: 'monospace',
            },
          },
          '&-plan': {
            textAlign: 'center',
            '& .modal_planName': {
              fontWeight: 400,
              color: '#fff',
              fontSize: '20px',
              '& .price': {
                fontSize: '24px',
                fontWeight: 700,
                display: 'inline-block',
                padding: '2px .15em 0 .35em',
              },
            },
            '& .icon-check': {
              display: 'block',
              width: '24px',
              height: '24px',
            },
          },
          '& .modal_clm_head': {
            position: 'relative',
            background: 'rgba(256,256,256, .1) !important',
            height: '56px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFeatureSettings: "'palt'",
          },
        },
        '&_cell': {
          boxSizing: 'border-box',
          padding: '0 12px',
          height: '32px',
          fontSize: '14px',
          fontFeatureSettings: "'palt'",
          '& + .modal_cell': {
            borderTop: '1px solid #444',
          },
          borderLeft: '1px solid #777',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&-long': {
            height: '100px',
            '& .price': {
              fontSize: '20px',
              fontWeight: '700',
            },
          },
          '&-rel': {
            flexDirection: 'column',
            textAlign: 'center',
          },
          '&-video': {
            height: 'calc(32px * 5)',
            flexDirection: 'column',
            fontSize: '16px',
            '& > p': {
              marginTop: '-12px',
            },
            '& .circle': {
              fontSize: '90px',
              lineHeight: 1.1,
            },
            '& .txt': {
              display: 'block',
            },
          },
          '&-btn': {
            position: 'relative',
            height: 'auto',
            borderTop: '1px solid #777 !important',
            padding: '16px 16px',
            '&-none': {
              borderLeft: 'none',
              backgroundColor: 'transparent !important',
            },
          },
        },
        '&_planBln': {
          position: 'relative',
          top: '-20px',
          color: '#f24848',
          background: '#fff',
          fontFeatureSettings: "'palt'",
          lineHeight: 1.35,
          padding: '8px 10px',
          margin: '0 auto -10px auto',
          '& .price': {
            fontSize: '20px',
            fontWeight: '700',
            padding: '0 .1em',
          },
          '&::after': {
            position: 'absolute',
            bottom: '-5px',
            left: '50%',
            zIndex: 0,
            display: 'block',
            width: '10px',
            height: '10px',
            boxSizing: 'border-box',
            marginLeft: '-3px',
            background: '#fff',
            content: "''",
            transform: 'rotate(45deg)',
          },
        },
        '&_btn': {
          display: 'block',
          width: '220px',
          borderRadius: '8px',
          backgroundColor: '#f24848',
          textAlign: 'center',
          padding: '10px 0 12px',
          position: 'relative',
          fontFeatureSettings: "'palt'",
          cursor: 'pointer',
          color: '#fff',
          '& .icon-forward': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: '4px',
            width: '24px',
            height: '24px',
            background:
              'url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22white%22%20d%3D%22M9.235%205L7.59%206.645L12.9333%2012L7.59%2017.355L9.235%2019L16.235%2012L9.235%205Z%22%20%2F%3E%3C%2Fsvg%3E) no-repeat center center',
          },
          '&:hover': {
            backgroundColor: '#f55',
          },
          '&_txt': {
            fontSize: '22px',
            fontWeight: 700,
            lineHeight: 1.2,
            '&Sub': {
              display: 'block',
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: 1,
              marginTop: '4px',
            },
          },
        },
        '&_help': {
          position: 'absolute',
          top: '12px',
          left: 0,
          color: '#fff',
          fontSize: '13px',
          '& .icon-faq': {
            display: 'inline-block',
            width: '14px',
            height: '14px',
            marginRight: '.3em',
            backgroundSize: '140%',
            verticalAlign: 'top',
          },
          '&:hover': {
            opacity: 0.7,
          },
        },
        '&_note': {
          fontFeatureSettings: "'palt'",
          color: '#fff',
          background: '#242424',
          display: 'block',
          width: '504px',
          padding: '12px 0 40px',
          whiteSpace: 'nowrap',
          fontSize: '14px',
          '&-emphasis': {
            color: '#fff000',
            '& br': {
              display: 'none',
            },
          },
        },
        '&_mno_campaign': {
          display: 'flex',
          width: '100%',
          boxSizing: 'border-box',
          margin: '16px 0',
          padding: '12px 30px 12px 12px',
          borderRadius: '4px',
          background: '#3a3a3a',
          alignItems: 'center',
          justifyContent: 'space-between',
          '& .pc-only': {
            display: 'block',
          },
          '&_lead': {
            color: '#fff',
            fontSize: '14px',
            lineHeight: 1.5,
            '& span': {
              color: '#fff000',
            },
          },
          '&_link': {
            color: '#fff',
            textAlign: 'center',
            fontSize: '16px',
            width: '633px',
            textDecorationLine: 'underline',
          },
        },
      },
      '@media (max-width: 969px)': {
        '& .modal_base': {
          width: '100%',
          position: 'absolute',
          maxHeight: '95vh',
          '& .modal_in': {
            padding: '0',
          },
          '& .modal_head': {
            padding: '28px 20px 24px',
          },
          '& .modal_ttl': {
            fontSize: '25px',
          },
          '& .modal_copy': {
            fontSize: '14px',
            display: 'none',
          },
          '& .modal_body': {
            marginTop: 0,
          },
          '& .modal_tbl': {
            borderRight: 'none',
          },
          '& .modal_clm': {
            '&-h': {
              width: '21vw',
              '& .modal_cell': {
                fontSize: '11px',
                letterSpacing: '-0.05em',
                fontFeatureSettings: "'palt'",
                '@media (max-width: 374px)': {
                  fontSize: '9px',
                },
              },
            },
            '&-free': {
              width: '10vw',
              minWidth: '4em',
              textAlign: 'center',
              color: '#9c9c9c',
              '& .modal_cell': {
                padding: 0,
              },
              '& .modal_planName': {
                fontSize: '12px',
                transform: 'scaleX(.9)',
                whiteSpace: 'nowrap',
              },
              '& .icon-close, & .icon-triangle, & .icon-check': {
                display: 'block',
                width: '20px',
                height: '20px',
              },
            },
            '&-plan': {
              width: '34.5vw',
              '& .modal_planName': {
                fontSize: '14px',
                '& .price': {
                  display: 'block',
                  fontSize: '18px',
                  padding: '2px 0',
                },
              },
              '& .icon-check': {
                display: 'block',
                width: '20px',
                height: '20px',
              },
            },
            '& .modal_head': {
              height: '72px !important',
            },
          },
          '& .modal_cell': {
            padding: '0 4px 0 4px',
            height: '32px',
            fontSize: '12px',
            '&-long': {
              height: '100px',
              '& .price': {
                fontSize: '14px',
              },
            },
            '&-video': {
              height: 'calc(32px * 5)',
              fontSize: '14px',
              '& .circle': {
                fontSize: '80px',
              },
            },
            '&-btn': {
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'rgba(0,0,0,.0)',
              backdropFilter: 'saturate(2) blur(4px)',
              height: '75px',
              padding: '8px',
              alignItems: 'flex-start',
            },
          },
          '& .modal_planBln': {
            padding: '6px 4px 4px',
            boxSizing: 'border-box',
            maxWidth: '200px',
            fontSize: '10px',
            lineHeight: 1.2,
            '& .price': {
              fontSize: '14px',
            },
          },
          '& .modal_btn': {
            width: '100%',
            padding: '10px 4px 11px 0',
            borderRadius: '8px',
            '& .icon-forward': {
              right: '0px',
              width: '18px',
              height: '18px',
              '@media (max-width: 374px)': {
                width: '16px',
                height: '16px',
              },
            },
            '&_txt': {
              fontSize: '18px',
              '&Sub': {
                fontSize: '11px',
                marginTop: '3px',
              },
              '@media (max-width: 374px)': {
                fontSize: '15px',
                '&Sub': {
                  fontSize: '10px',
                  marginTop: '2px',
                },
              },
            },
          },
          '& .modal_help': {
            top: '8px',
            right: 'auto',
            bottom: 'auto',
            left: '4px',
            boxSizing: 'border-box',
            padding: 0,
            fontSize: '10px',
            '& .icon-faq': {
              width: '10px',
              height: '10px',
            },
          },
          '& .modal_note': {
            whiteSpace: 'nowrap',
            padding: '8px 8px 40px',
            fontSize: '13px',
            '&-emphasis': {
              '& br': {
                display: 'block',
              },
            },
          },
          '& .modal_mno_campaign': {
            width: '95%',
            margin: '16px auto',
            padding: '12px',
            flexFlow: 'column',
            '& .pc-only': {
              display: 'none',
            },
            '&_lead': {
              marginBottom: '10px',
            },
          },
        },
      },
    },
  }),
);

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
const PlanModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { debugTime } = usePlStoreStateContext();
  const [isMnoBundlePromotionNow, setIsMnoBundlePromotionNow] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) return;

    const fetch = async () => {
      try {
        const res = await axios.get('/api/time/');
        const now = debugTime ? dayjs(debugTime) : dayjs((res?.data as ResDataType)?.time ?? '');

        setIsMnoBundlePromotionNow(now.isSameOrAfter('2023-04-01 00:00:00'));
      } catch (e) {
        //
      }
    };
    fetch().finally(() => null);
  }, [debugTime, isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className="modal_base">
          <Button className="modal_closeBtn" onClick={onClose} />
          <div className="modal_in">
            <div className="modal_head">
              <h2 className="modal_ttl">料金プランは2種類</h2>
              <p className="modal_copy">定額プランに加入すると、パ・リーグ主催の公式戦やオリジナル動画が見放題！</p>
            </div>
            <div className="modal_body">
              <div className="modal_tbl">
                <ol className="modal_clm modal_clm-h">
                  <li className="modal_cell modal_clm_head" />
                  <li className="modal_cell">LIVE配信</li>
                  <li className="modal_cell">見逃し配信</li>
                  <li className="modal_cell">ダイジェスト</li>
                  <li className="modal_cell">ヒーローインタビュー</li>
                  <li className="modal_cell">オリジナル番組</li>
                  <li className="modal_cell modal_cell-long">価格</li>
                  <li className="modal_cell modal_cell-btn modal_cell-btn-none" />
                </ol>
                <ol className="modal_clm modal_clm-free">
                  <li className="modal_cell modal_clm_head">
                    <h3 className="modal_planName">無料会員</h3>
                  </li>
                  <li className="modal_cell">△</li>
                  <li className="modal_cell">×</li>
                  <li className="modal_cell">○</li>
                  <li className="modal_cell">○</li>
                  <li className="modal_cell">×</li>
                  <li className="modal_cell modal_cell-long">
                    <span className="price">無料</span>
                  </li>
                  <li className="modal_cell modal_cell-btn modal_cell-btn-none" />
                </ol>
                <ol className="modal_clm modal_clm-plan">
                  <li className="modal_cell modal_clm_head">
                    <h3 className="modal_planName">月額プラン会員</h3>
                  </li>
                  <li className="modal_cell modal_cell-video">
                    <p>
                      <span className="circle">○</span>
                      <br />
                      すべて見放題
                    </p>
                  </li>
                  <li className="modal_cell modal_cell-long">
                    <p>
                      <span className="price">月額￥702</span>（税込）
                    </p>
                  </li>
                  <li className="modal_cell modal_cell-btn">
                    <a
                      href={`${NEXT_PUBLIC_RTV_WEB_URL}/item_purchase/504347/`}
                      className="modal_btn l-id_modal_pacificleague_month"
                    >
                      <span className="modal_btn_txt">
                        月額プラン<span className="modal_btn_txtSub">購入手続きへ</span>
                      </span>
                      <span className="icon-forward" />
                    </a>
                  </li>
                </ol>
                <ol className="modal_clm modal_clm-plan">
                  <li className="modal_cell modal_clm_head">
                    <h3 className="modal_planName">年額プラン会員</h3>
                  </li>
                  <li className="modal_cell modal_cell-video">
                    <p>
                      <span className="circle">○</span>
                      <br />
                      すべて見放題
                    </p>
                  </li>
                  <li className="modal_cell modal_cell-long modal_cell-rel">
                    <p className="modal_planBln">
                      月額プランより
                      <br />
                      年間<span className="price">¥2,575</span>も<span className="price">お得!!</span>
                    </p>
                    <p>
                      <span className="price">年額¥5,602</span>（税込）
                    </p>
                  </li>
                  <li className="modal_cell modal_cell-btn">
                    <a
                      href={`${NEXT_PUBLIC_RTV_WEB_URL}/item_purchase/507704/`}
                      className="modal_btn l-id_modal_pacificleague_annual"
                    >
                      <span className="modal_btn_txt">
                        年額プラン<span className="modal_btn_txtSub">購入手続きへ</span>
                      </span>
                      <span className="icon-forward" />
                    </a>
                  </li>
                </ol>
              </div>

              <div className="modal_mno_campaign">
                <p className="modal_mno_campaign_lead">
                  <span>【楽天モバイルご契約者様へ】</span>
                  <br />
                  {isMnoBundlePromotionNow ? (
                    <>
                      2023年4月1日（土）より、楽天モバイルご契約者様であれば、楽天モバイルの毎月お支払いのご利用料金のみで『Rakuten
                      パ・リーグ Special』がご視聴いただけます。
                    </>
                  ) : (
                    <>
                      2023年4月1日（土）より、楽天モバイルご契約者様であれば、楽天モバイルの毎月お支払いのご利用料金のみで『Rakuten
                      パ・リーグ
                      Special』がご視聴いただけます。ご購入された後、返金等は一切お受けできかねますのでご了承ください。
                      <br className="pc-only" />
                      ※詳細は2023年4月1日にご案内いたします。今しばらくお待ちくださいませ。
                    </>
                  )}
                </p>
                <a
                  className="modal_mno_campaign_link"
                  href={`${NEXT_PUBLIC_RTV_WEB_URL}/static/pacificleague/lp/mobile_cpn`}
                >
                  キャンペーン詳細はこちら &gt;&gt;
                </a>
              </div>

              <p className="modal_note">
                ※購入後、いつでも解約可能です
                <br />
                ※月額・年額ともに契約期間内に
                <span className="modal_note-emphasis">
                  <br />
                  解約されない限り「自動更新」
                </span>
                されます
              </p>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default PlanModal;
