import * as Types from '@/api/@types';
import * as WebApiTypes from '@/modules/api/@types';

const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;

export const HIDDEN_TEXT_OF_HOW_TO_CONTENTS: Array<number> = [395562];

export const HIDDEN_DETAIL_CONTENTS: Array<number> = [438012, 438013, 438014, 438015, 438016, 438017];

type RedirectContentsType = {
  [key: string]: {
    start: string;
    end: string;
    url: string;
  };
};
export const REDIRECT_CONTENTS: RedirectContentsType = {
  '396529': {
    start: '',
    end: '',
    url: `${NEXT_PUBLIC_RTV_WEB_URL}/content/396707`,
  },
};

export const ContentConsts = {
  TYPE: {
    /** 作品（単品） */
    PRODUCT: 1,
    /** シーズン（各話あり） */
    SEASON: 2,
    /** 各話 */
    EPISODE: 3,
    /** パック */
    PACK: 4,
    /** 見放題パック */
    SVOD: 5,
  },
  ITEM: {
    TYPE: {
      /** VOD */
      VOD: 1,
      /** EST */
      EST: 2,
      /** SVOD */
      SVOD: 3,
      /** LIVE */
      LIVE: 4,
      /** PROMOTION */
      PROMOTION: 5,
      /** PEEP */
      PEEP: 6,
      /** PROMOTION_EST */
      PROMOTION_EST: 7,
      /** ADD ON SVOD */
      ADD_ON_SVOD: 8,
      /** SALE RESTRICT */
      SALE_RESTRICT: 9,
      /** SVOD(応当日対応) */
      OTOBI: 10,
      /** 年額応当日対応 */
      ANNUAL_OTOBI: 11,
    },
    GOODS: {
      SALE_TYPE: {
        /** 通常販売 */
        NORMAL: 0,
        /** 限定販売/在庫有無のみ */
        LIMITED_HAS_STATUS: 1,
        /** 限定販売/在庫状況 */
        LIMITED_HAS_NUMBER: 2,
      },
      INVENTORY_STATUS: {
        IN_STOCK: 0,
        FEW_LEFT: 1,
        NO_STOCK: 2,
      },
    },
  },
  PACK: {
    TYPE: {
      /** 全話 */
      ALL: '1',
      /** 混合 */
      MIX: '2',
    },
  },
  SUPPORTED_DEVICE_INDEX: {
    CHROMECAST: 4,
  },
  GENRE: {
    ID: {
      ALL: 0,
      TAKARAZUKA: 1,
      STAGE: 2,
      ANIME: 3,
      MUSIC: 4,
      SPORTS: 5,
      SHOW: 6,
    },
    CODE: {
      ALL: 'all',
    },
  },
} as const;

export const TEST_MOVIE_CONTENT_ID = 387878;

export const GENRES_FALLBACK: Array<Types.SelectedGenre> = [
  {
    id: 0,
    code: 'all',
    name: 'すべて',
    rtv_genre_id: null,
    rtv_sub_genre_ids: null,
    color_code: {
      base: '#2E363A',
      light: '#FDFDFD',
    },
  },
  {
    id: 1,
    code: 'takarazuka',
    name: '宝塚',
    rtv_genre_id: 12,
    rtv_sub_genre_ids: [124, 125, 126, 127, 128],
    color_code: {
      base: '#E1CF6E',
      light: '#EFE8C2',
    },
  },
  {
    id: 2,
    code: 'stage',
    name: '舞台',
    rtv_genre_id: 12,
    rtv_sub_genre_ids: [129, 176, 178, 179],
    color_code: {
      base: '#604036',
      light: '#B67662',
    },
  },
  {
    id: 3,
    code: 'animation',
    name: 'アニメ・声優',
    rtv_genre_id: 7,
    rtv_sub_genre_ids: [90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 193, 217, 218, 219, 220],
    color_code: {
      base: '#6289B7',
      light: '#83A0C2',
    },
  },
  {
    id: 4,
    code: 'music',
    name: '音楽',
    rtv_genre_id: 13,
    rtv_sub_genre_ids: [130, 131, 132, 133],
    color_code: {
      base: '#323538',
      light: '#5A6166',
    },
  },
  {
    id: 5,
    code: 'sports',
    name: 'スポーツ',
    rtv_genre_id: 15,
    rtv_sub_genre_ids: [149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159],
    color_code: {
      base: '#4E6051',
      light: '#93B699',
    },
  },
  {
    id: 6,
    code: 'event',
    name: 'イベント・ショー',
    rtv_genre_id: null,
    rtv_sub_genre_ids: null,
    color_code: {
      base: '#3E5C63',
      light: '#9FC5CD',
    },
  },
];

export const PAGINATION_FALLBACK: Types.Pagination = {
  current_page: 1,
  per_page: 1,
  last_page: 1,
  first_page_url: '',
  last_page_url: '',
  next_page_url: '',
  prev_page_url: '',
  links: null,
  path: '',
  from: 0,
  to: 0,
  total: 0,
};

export const RECOMMENDS_FALLBACK: WebApiTypes.Search = {
  available_premium: '',
  suggestion: null,
  cast_staff_list: [],
  gsp_var: '',
  offset: '',
  count: '',
  max_count: 0,
  content_list: [],
  facet: null,
  campaign_info: null,
};
